import { StaticImage } from "gatsby-plugin-image";
import HeaderBar from "../../components/HeaderBar";
import React from "react";

const HistoricalPresence = () => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          textAlign: "center",
          width: "100%",
          zIndex: 100,
        }}
      >
        <HeaderBar />
      </div>
      <div className="center-box">
        <StaticImage
          src="../../images/TGT - Final Maps Rasterized - 220224 - 00_B.png"
          alt="Map: Indigenous Presence in the GTA"
          placeholder="blurred"
          width={3000}
        />
      </div>
    </>
  );
};

export default HistoricalPresence;
